<template>
    <div >
    <!--  Content Start -->
        <section class="sabc_tv_schedule_sec">
         <div class="container">
            <div class="row">
               <div class="col-12 col-xl-8 col-lg-8 col-md-12 col-sm-12">
                  <!-- tv schedule start -->
                  <h1>
                     TV Schedule
                  </h1>
                  <!-- tv schedule logo start -->
                  <div class="sabc_tv_schedule_logo">
                     <ul>
                        <li :class="(selectedChannel == 1) ? 'selected' : ''" @click="getTvChannelProgram(1, selectedTab)">
                           <router-link :to="{ name: 'tv_schedule', params: { channel: 'sabc_sport', date: selectedTab}}">
                              <img class="sabc_tv_select_pic" :src="require('@/assets/images/sabc_tv_schedule_sport_sabc.svg')" alt="SABC Sport Channel Logo" width="188" height="70">
                              <img class="sabc_tv_unselect_pic" :src="require('@/assets/images/sabc_tv_schedule_sport_sabc_unselect.svg')" alt="SABC Sport Channel Logo" width="188" height="70">
                           </router-link>
                        </li>
                        <!-- <li :class="(selectedChannel == 2) ? 'selected' : ''" @click="getTvChannelProgram(2, selectedTab)">
                           <router-link :to="{ name: 'tv_schedule', params: { channel: 'sabc_1', date: selectedTab}}">
                              <img :src="require('@/assets/images/sabc_tv_schedule_sabc1.svg')" alt="SABC 1 Channel Logo" width="188" height="70">
                           </router-link>
                        </li>
                        <li :class="(selectedChannel == 3) ? 'selected' : ''" @click="getTvChannelProgram(3, selectedTab)">
                           <router-link :to="{ name: 'tv_schedule', params: { channel: 'sabc_2', date: selectedTab}}">
                              <img src="/public/images/sabc_tv_schedule_sabc2.webp" alt="SABC 2 Channel Logo" width="188" height="70">
                           </router-link>
                        </li>
                        <li :class="(selectedChannel == 4) ? 'selected' : ''" @click="getTvChannelProgram(4, selectedTab)">
                           <router-link :to="{ name: 'tv_schedule', params: { channel: 'sabc_3', date: selectedTab}}">
                              <img class="sabc_tv_unselect_pic" src="/public/images/sabc_tv_schedule_sabc3.webp" alt="SABC 3 Channel Logo" width="188" height="70">
                              <img class="sabc_tv_select_pic" src="/public/images/sabc_tv_schedule_sabc3_select.webp" alt="SABC 3 Channel Logo" width="188" height="70">
                           </router-link>
                        </li> -->
                     </ul>
                  </div>
                  <!-- tv schedule tabs -->
                  <div class="sabc_tv_schedule_tabs">
                     <ul>
                        <li @click="getTvChannelProgram(selectedChannel, moment().format('YYYY-MM-DD'))">
                           <router-link :to="{ name: 'tv_schedule', params: { channel: this.channelName[selectedChannel-1], date: moment().format('YYYY-MM-DD')}}" :class="(selectedTab == moment().format('YYYY-MM-DD')) ? 'selected' : ''">
                              Today
                           </router-link>
                        </li>
                        <li @click="getTvChannelProgram(selectedChannel, moment().add(1,'days').format('YYYY-MM-DD'))">
                           <router-link :to="{ name: 'tv_schedule', params: { channel: this.channelName[selectedChannel-1], date: moment().add(1,'days').format('YYYY-MM-DD')}}" :class="(selectedTab == moment().add(1,'days').format('YYYY-MM-DD')) ? 'selected' : ''">
                              Tomorrow
                           </router-link>
                        </li>
                        <li @click="getTvChannelProgram(selectedChannel, moment().add(2,'days').format('YYYY-MM-DD'))">
                           <router-link :to="{ name: 'tv_schedule', params: { channel: this.channelName[selectedChannel-1], date: moment().add(2,'days').format('YYYY-MM-DD')}}" :class="(selectedTab == moment().add(2,'days').format('YYYY-MM-DD')) ? 'selected' : ''">
                              {{moment().add(2,'days').format('ddd Do')}}
                           </router-link>
                        </li>
                        <li @click="getTvChannelProgram(selectedChannel, moment().add(3,'days').format('YYYY-MM-DD'))">
                           <router-link :to="{ name: 'tv_schedule', params: { channel: this.channelName[selectedChannel-1], date: moment().add(3,'days').format('YYYY-MM-DD')}}" :class="(selectedTab == moment().add(3,'days').format('YYYY-MM-DD')) ? 'selected' : ''">
                              {{moment().add(3,'days').format('ddd Do')}}
                           </router-link>
                        </li>
                        <li @click="getTvChannelProgram(selectedChannel, moment().add(4,'days').format('YYYY-MM-DD'))">
                           <router-link :to="{ name: 'tv_schedule', params: { channel: this.channelName[selectedChannel-1], date: moment().add(4,'days').format('YYYY-MM-DD')}}" :class="(selectedTab == moment().add(4,'days').format('YYYY-MM-DD')) ? 'selected' : ''">
                              {{moment().add(4,'days').format('ddd Do')}}
                           </router-link>
                        </li>
                        <li @click="getTvChannelProgram(selectedChannel, moment().add(5,'days').format('YYYY-MM-DD'))">
                           <router-link :to="{ name: 'tv_schedule', params: { channel: this.channelName[selectedChannel-1], date: moment().add(5,'days').format('YYYY-MM-DD')}}" :class="(selectedTab == moment().add(5,'days').format('YYYY-MM-DD')) ? 'selected' : ''">
                              {{moment().add(5,'days').format('ddd Do')}}
                           </router-link>
                        </li>
                        <li @click="getTvChannelProgram(selectedChannel, moment().add(6,'days').format('YYYY-MM-DD'))">
                           <router-link :to="{ name: 'tv_schedule', params: { channel: this.channelName[selectedChannel-1], date: moment().add(6,'days').format('YYYY-MM-DD')}}" :class="(selectedTab == moment().add(6,'days').format('YYYY-MM-DD')) ? 'selected' : ''">
                              {{moment().add(6,'days').format('ddd Do')}}
                           </router-link>
                        </li>
                     </ul>
                  </div>
                  <!-- Tv programm schedule -->
                  <div class="sabc_tv_programm_list" v-if="is_data_ready">
                     <div class="sabc_tv_programm_row" v-for="program in programList" :class="(program.live == 1)?'sabc_tv_programm_live':''">
                        <div class="sabc_tv_programm_timestatus">
                           <p>{{program.time_slot}}</p>
                           <label v-if ="program.live">
                              <img :src="require('@/assets/images/sabc_live_icon.webp')" alt="LIVE">
                              LIVE
                           </label>
                        </div>
                        <div class="sabc_tv_programm_dtl">
                           <h2>
                              {{program.episode_title}}
                           </h2>
                           <p v-html="program.description"></p>
                           <router-link :to="{ name: 'tv_live'}" v-if ="program.live">
                              <img :src="require('@/assets/images/sbac_hdr_watchvideobtn.webp')" alt="sbac_hdr_watchvideobtn">
                              Watch
                           </router-link>
                        </div>
                     </div>
                  </div>
                  <!-- tv schedule end -->
               </div>
               <SideBar :editorsPick="editorsPick" :bannerImages="bannerImages" :bannerEmbed="bannerEmbed"></SideBar>
            </div>
         </div>
      </section>
    </div>
    <!--  Content end -->
</template>

<script>

    import moment from 'moment';
    import SideBar from '@/components/common/sidebar/sidebar';
    export default {
        name: 'tv-schedule',
        data() {
            return {
               selectedTab : moment().format('YYYY-MM-DD'),
               selectedChannel : 1,
               editorsPick: [],
               channelName: ['sabc_sport', 'sabc_1', 'sabc_2', 'sabc_3'],
               programList:[],
               bannerImages:[],
               bannerEmbed:[],
            }
        },
        components: {
            SideBar
        },
        mounted() {
           this.getTvData();

        },
       watch: {
          $route(to, from) {
             this.getTvData();
          }
       },
        serverPrefetch() {
            return this.getTvData();
        },
        computed: {

        },
        metaInfo() {
           if (this.$store.state.siteSetting && this.is_data_ready) {
                let ss = this.$store.state.siteSetting;

                let title = this.$getSafe(() => ss.tv_schedule_meta_title);
                let description = this.$getSafe(() => ss.tv_schedule_meta_description);
                let keywords = this.$getSafe(() => ss.tv_schedule_meta_keywords);

                title = title.replace(' - ', this.selectedTab+' - ');
                
                return {
                    title: title,
                    description: description,
                    meta: this.$getMetaTags(title, description, keywords),
                    link: [
                        {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}
                    ],
                }
            }

        },

        methods: {
            moment: function () {
                return moment();
            },
            getTvData() {
               if(this.$route.params.date !== undefined) {
                  this.selectedTab = this.$route.params.date;
                  this.selectedChannel = this.channelName.indexOf(this.$route.params.channel)+1;
               } else {
                  this.selectedTab = moment().format('YYYY-MM-DD');
                  this.selectedChannel = 1;
               }
               let params = {channel: this.selectedChannel, date:this.selectedTab}

               this.is_data_ready = false;
               return this.$axios.get(this.$GetTvData,{params})
                     .then(response => {
                           if (response.data) {
                              this.is_data_ready = true;
                              this.programList = response.data.tvData
                              this.editorsPick = response.data.editorsPick
                              this.bannerImages = response.data.bannerImages
                              this.bannerEmbed = response.data.bannerEmbed
                           }
                     })
                     .catch(error => {
                     })
            },
            getTvChannelProgram(selectedChannelID, selectedDateTab) {
               this.selectedTab = selectedDateTab;
               this.selectedChannel = selectedChannelID;
               let params = {channel: selectedChannelID, date:selectedDateTab}
                return this.$axios.get(this.$GetTvData, {params})
                     .then(response => {
                        this.programList = response.data.tvData
                     })
                     .catch(error => {
                     })
            },
        }
    }

</script>
<style lang="scss">
@import '~@/assets/scss/tv_schedule.scss';
@import '~@/assets/scss/right_sidebar.scss';
.sabc_tv_schedule_logo ul li.selected{
   background: #0C2251;
}
</style>
